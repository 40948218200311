import routes from "Constants/Route.constants";
import React from "react";
import VagonDesktop from "Assets/images/vagon-desktop.png";
import { Modal, Button } from "UI";
import { kebabToCamel } from "Utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const WorkstationError = ({ match, history, translate }) => {
  const location = useLocation();
  const currentPage = kebabToCamel(match.path.substring(1));

  const onCancel = () => {
    history.push(overrideDashboardRoute || routes.home);
  };

  const { overrideDashboardRoute, overrideRetryRoute } = location.state || {};

  return (
    <>
      <img src={VagonDesktop} alt="vagon-logo" className="workstation-error-image" />
      <Modal
        className="centered"
        headerText={translate(`${currentPage}.header`)}
        descriptionText={translate(`${currentPage}.description`)}
        closeAction={onCancel}
        topRightIcon="close"
        topRightIconAction={onCancel}
        buttonText={translate(`${currentPage}.buttonText`)}
        buttonAction={() => {
          if (currentPage === "streamConnectionFailed") {
            window.location.href = overrideRetryRoute || routes.home;
            return;
          }
          history.push(routes.home);
        }}
        overrideButton={
          (currentPage === "expired" || currentPage === "connectionFailed") && (
            <div className="workstation-error-buttons-container">
              <Button
                text={translate(`${currentPage}.buttonText`)}
                onClick={() => history.push(overrideDashboardRoute || routes.home)}
                className="new-stream-button"
              />
              <Button
                text={translate(`${currentPage}.retryButtonText`)}
                onClick={() => history.push(overrideRetryRoute || routes.workstation)}
                className="new-stream-button"
              />
            </div>
          )
        }
        errorable
        small
      />
    </>
  );
};

export default WorkstationError;
