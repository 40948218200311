/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import {
  INTERNAL_SDK_MESSAGES,
  KEY_MAPPING_OPTIONS,
  LOCAL_STORAGE,
  WORKSTATION_FRIENDLY_STATUS,
} from "Constants/global.constants";
import { apiGenerator, isMobile, Logger } from "Utils";
import {
  setupVideoPlayer,
  setupRenderStreaming,
  fullscreen,
  requestPointerLockForPlayer,
} from "Utils/RenderStreaming/Player/main";
import routes from "Constants/Route.constants";
import { DOCK_POSITIONS, DOCK_VISIBILITY, ERRORS, ERROR_CODE } from "Constants/AppStreaming.constants";
import {
  ToastNotificationContainer,
  showToastNotification,
} from "Components/Workstation/ToastNotification/ToastNotification";
import AppStreamingLoader from "Components/AppStreaming/AppStreamingLoader/AppStreamingLoader.component";
import AppStreamingDock from "Components/AppStreaming/AppStreamingDock/AppStreamingDock.component";
import { useHotkeys } from "react-hotkeys-hook";
import { getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import IdleTracker from "Utils/Components/IdleTracker/IdleTracker.component";
import SDKInternal from "Utils/Workstation/SDKInternal";

import "./RenderStreaming.styles.scss";

export function Hotkey({ hotkey, callback }) {
  useHotkeys(hotkey, callback);
  return null;
}

const RenderStreamingContainer = ({ history, translate, match }) => {
  const [connecting, setConnecting] = useState(false);
  const isPlayButtonClickedRef = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isDockOpen, setIsDockOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [quality, setQuality] = useState("standard");
  const [micEnabled, setMicEnabled] = useState(false);
  const [isGameModeDisabledNotificationShown, setIsGameModeDisabledNotificationShown] = useState(false);
  const [resetIdleTracker, setResetIdleTracker] = useState(false);

  const sessionExpireTimeoutRef = useRef(null);

  const streamUid = match.params?.uri;

  const IDLE_TIME_WARNING_OFFSET = 20000;

  const sendParentMessageAndRedirect = (error) => {
    const message = JSON.stringify({ error });
    window.parent.postMessage(message, "*");

    setTimeout(() => {
      history.push({
        pathname: routes.appStreamingError,
        state: {
          streamID,
          error: ERRORS.SESSION_IDLE,
        },
      });
    }, 1000);
  };

  const { data: machineData } = useQuery({
    queryKey: [API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)),
    enabled: !!streamUid,
    refetchInterval: 7000,
    keepPreviousData: true,
    onError: (err) => {
      const { client_code: clientCode } = err?.response?.data;

      let error = ERRORS.STREAM_NOT_FOUND;
      if (clientCode === ERROR_CODE.INSTALLATION_FAILED) {
        error = ERRORS.INSTALLATION_FAILED;
      }

      if (clientCode === ERROR_CODE.SESSION_EXPIRED) {
        error = ERRORS.SESSION_EXPIRED;
      }

      sendParentMessageAndRedirect(error);
    },
  });

  const machine = machineData?.data;

  const {
    friendly_status: friendlyStatus,
    application,
    dark_mode: darkMode,
    texts,
    dock_position: dockPosition,
    key_mapping_selection: keyMappingSelection,
    microphone,
    maximum_session_duration: maxDuration,
    active_session_start_at: startAt,
    maximum_session_duration_enabled: maxSessionDurationEnabled,
    turn_credentials: turnCredentials,
    idle_duration: idleDuration,
    active_stream_id: streamID,
  } = machine?.attributes || {};

  const { banner_url: bannerURL, logo_url: logoURL, enterprise, name } = application?.attributes || {};

  useEffect(() => {
    if (maxSessionDurationEnabled && startAt) {
      const estimatedRemainingSeconds = new Date(startAt).getTime() + maxDuration * 60 * 1000 - new Date().getTime();

      const timeout = setTimeout(() => {
        showToastNotification({
          description: translate("sessionExpireMessage"),
          autoClose: 50000,
        });
      }, estimatedRemainingSeconds - 60000);

      if (sessionExpireTimeoutRef.current) {
        clearTimeout(sessionExpireTimeoutRef.current);
      }
      sessionExpireTimeoutRef.current = timeout;
    }
  }, [maxSessionDurationEnabled, maxDuration, startAt]);

  const onIdleTimeEnd = () => {
    const message = JSON.stringify({ error: ERRORS.SESSION_IDLE });
    window.parent.postMessage(message, "*");
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const playButtonIdleTimeEnd = () => {
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const onWarningTimeEnd = () => {
    showToastNotification({
      description: translate("sessionIdleMessage"),
    });
  };

  const playButtonWarningEnd = () => {
    showToastNotification({
      description: translate("pixelStreamingSessionIdleMessage"),
      autoClose: 10000,
    });
  };

  const onDuplicateTab = () => {
    if (enterprise) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onFailed);
    }
    const message = JSON.stringify({ error: ERRORS.DUPLICATE_TAB });
    window.parent.postMessage(message, "*");
    history.push({
      pathname: routes.appStreamingError,
      state: {
        streamID,
        error: ERRORS.SESSION_EXPIRED,
      },
    });
  };

  useEffect(() => {
    if (machine && machine?.attributes?.friendly_status === WORKSTATION_FRIENDLY_STATUS.READY && !isConnected) {
      setupVideoPlayer({
        streamUid,
        audioOutputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice, null)?.deviceId,
        setShowLoader,
        gameModeEnabled: keyMappingSelection !== KEY_MAPPING_OPTIONS.CLICK,
        turnCredentials,
        onGameModeDisabled,
        onResetIdleTracker,
        onDuplicateTab,
      });
      setIsConnected(true);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonWarningEnd();
        }
      }, 45000);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonIdleTimeEnd();
        }
      }, 60000);
      Logger.log("pixelStreamingStarted");
    }
  }, [machine]);

  const changeSoundState = (state) => {
    setSoundEnabled(state);
    const player = document.getElementById("streamingVideo");
    if (player) {
      player.muted = !state;
      const audioTrack = player?.srcObject?.getAudioTracks()?.[0];
      if (audioTrack) {
        audioTrack.enabled = state;
      }
    }
  };

  const toggleFullscreen = () => {
    fullscreen();
  };

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
    // handle mic
  };

  const onGameModeDisabled = () => {
    if (!isGameModeDisabledNotificationShown) {
      setTimeout(() => {
        showToastNotification({
          header: translate("360viewMode.disabled.header"),
          description: translate("360viewMode.disabled.description"),
        });
      }, 10);
      setIsGameModeDisabledNotificationShown(true);
    }
  };

  const onResetIdleTracker = () => {
    setResetIdleTracker(!resetIdleTracker);
  };

  useEffect(() => {
    // setOnGameModeDisabled(onGameModeDisabled);
  }, [isGameModeDisabledNotificationShown]);

  return (
    <div className="render-streaming-container">
      <Hotkey
        hotkey="ctrl+g"
        callback={() => {
          requestPointerLockForPlayer();
        }}
      />
      <div id="playerUI" className="noselect">
        <div id="playerPixel">
          {machine && showLoader && (
            <div className="loading-container">
              <AppStreamingLoader
                status={connecting ? "ready" : friendlyStatus}
                texts={texts}
                dark={darkMode}
                enterprise={enterprise}
                bannerURL={bannerURL}
                logoURL={logoURL}
                translate={translate}
                appName={name}
                connected
                showPlayButton={!connecting}
                initialPercentage={85}
                microphone={microphone}
                handlePlayButtonClick={() => {
                  setConnecting(true);
                  setupRenderStreaming();
                  isPlayButtonClickedRef.current = true;
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ToastNotificationContainer />
      {isConnected && !isMobile && dockPosition !== DOCK_POSITIONS.hide && (
        <AppStreamingDock
          isDockOpen={isDockOpen}
          displayDockButton={false}
          setIsDockOpen={setIsDockOpen}
          translate={translate}
          soundEnabled={soundEnabled}
          changeableSound={false}
          dockVisibility={DOCK_VISIBILITY.always_visible}
          dockColor=""
          changeSoundState={changeSoundState}
          quality={quality}
          setQuality={setQuality}
          changeableQuality={false}
          toggleFullscreen={toggleFullscreen}
          isMicEnabled={micEnabled}
          toggleMic={toggleMic}
          gameModeEnabled={false}
          changeGameMode={() => {}}
          submitStreamPreference={() => {}}
          connected={isConnected}
          dockPosition={dockPosition}
          clickToStart={showLoader}
          pixelStreaming
        />
      )}
      {!showLoader && idleDuration > 0 && (
        <IdleTracker
          idleDuration={idleDuration}
          onIdleTimeEnd={onIdleTimeEnd}
          warningTimeOffset={IDLE_TIME_WARNING_OFFSET}
          onWarningTimeEnd={onWarningTimeEnd}
          reset={resetIdleTracker}
        />
      )}
    </div>
  );
};

export default RenderStreamingContainer;
