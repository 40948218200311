import { STREAM_TYPE } from "Constants/global.constants";
import { Logger, isFirefox, isSafari } from "Utils";
import Signaler from "./signaler";

export default class WebRTC {
  onopen;

  onclose;

  onmessage;

  onerror;

  constructor(
    sessionId,
    videoElement,
    turnUri = null,
    turnPassword = null,
    streamType = STREAM_TYPE.workstation,
    machineId = null,
    turnCredentials = null,
    vrElement = null,
    streamParams = {},
  ) {
    Logger.log("WebRTC | constructor", turnCredentials);
    const configuration = {
      iceServers: [],
      bundlePolicy: "max-compat",
    };
    if (turnCredentials) {
      configuration.iceServers.push({
        urls: `${turnCredentials.url}?transport=udp`,
        username: turnCredentials.username,
        credential: turnCredentials.password,
      });
      configuration.iceServers.push({
        urls: `${turnCredentials.url.replace("turn:", "turns:")}?transport=tcp`,
        username: turnCredentials.username,
        credential: turnCredentials.password,
      });
    } else if (turnUri && turnPassword) {
      configuration.iceServers.push({
        urls: turnUri,
        username: "vagon",
        credential: turnPassword,
      });
    }
    this.videoElement = videoElement;
    this.vrElement = vrElement;
    this.sessionId = sessionId;
    this.streamType = streamType;
    this.readyState = WebSocket.CONNECTING;
    this.offerReceived = false;
    this.iceCandidates = [];
    this.dataChannel = null;
    this.micTrack = null;
    this.signaler = new Signaler(
      this.onSignalerConnected,
      this.onSignalerDisconnected,
      this.onSignalerOffer,
      this.onSignalerCandicate,
      this.onMultipleConnection,
      this.streamType,
      machineId,
      streamParams,
    );
    this.peerConnection = new RTCPeerConnection(configuration);
    this.peerConnection.onicecandidate = this.onIceCandidate;
    this.peerConnection.ontrack = this.onTrack;
    this.peerConnection.onnegotiationneeded = this.onNegotiationNeeded;
    this.peerConnection.oniceconnectionstatechange = this.onIceConnectionStateChange;
    this.peerConnection.ondatachannel = this.onDataChannel;
    this.signaler.connect(this.sessionId);
    this.disconnectControlTimeoutHandler = null;
    Logger.log("WebRTC | constructor", this.streamType);
  }

  // Websocket Function (Like Interface)
  send = (msg) => {
    if (this.dataChannel && this.dataChannel.readyState === "open") {
      this.dataChannel.send(msg);
    }
  };

  clear = () => {
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }
    if (this.signaler) {
      this.signaler.sendDisconnected();
      this.signaler.disconnect();
      this.signaler = null;
    }
    setTimeout(this.onclose, 700);
  };

  close = () => {
    if (this.signaler) {
      this.signaler.sendDisconnected();
      this.signaler.disconnect();
      this.signaler = null;
      // test
    }
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
      this.onclose();
    }
  };

  // Signaler Functions
  onSignalerConnected = () => {
    this.signaler.sendInit();
  };

  onSignalerDisconnected = () => {
    this.clear();
  };

  onSignalerOffer = (sdp) => {
    const desc = new RTCSessionDescription({
      type: "offer",
      sdp,
    });
    this.peerConnection
      .setRemoteDescription(desc)
      .then(() => {
        const micChannel = this.peerConnection.getTransceivers().find((t) => t.mid === "mic");
        if (micChannel) {
          micChannel.direction = "sendonly";
        }
        this.peerConnection.createAnswer().then((answer) => {
          this.peerConnection.setLocalDescription(answer);
          this.signaler.sendAnswer(answer);
        });
      })
      .catch((error) => {
        Logger.error("WebRTC | setREmoteDescriptionError: ", error);
      });
    this.iceCandidates.forEach((ice) => {
      this.peerConnection.addIceCandidate(ice).catch((error) => {
        Logger.error("WebRTC | addIceCandidateError: ", error);
      });
    });
    this.offerReceived = true;
  };

  onSignalerCandicate = (candidate) => {
    Logger.log("WebRTC | onSignalCandicate: ", candidate);
    const ice = new RTCIceCandidate({
      candidate: candidate.sdp,
      sdpMid: candidate.sdpMid,
      sdpMLineIndex: candidate.sdpMlineIndex,
    });
    if (this.offerReceived) {
      this.peerConnection.addIceCandidate(ice).catch((error) => {
        Logger.error("WebRTC | addIceCandidateError: ", error);
      });
    } else {
      this.iceCandidates.push(ice);
    }
  };

  arrayBufferToString = (buffer) => {
    // eslint-disable-next-line no-new-wrappers
    return new String(buffer);
  };

  // PEER CONNECTION METHODS
  onIceCandidate = (event) => {
    Logger.log("WebRTC | onIceCandidate: ", event);
    if (event.candidate && event.candidate.candidate !== "") {
      this.signaler.sendCandidate(event.candidate);
    }
  };

  onTrack = (event) => {
    Logger.log("WebRTC | onTrack: ", event);
    if (this.streamType === STREAM_TYPE.pixel_streaming) {
      Logger.log("WebRTC | Pixel Streaming Return |onTrack: ", event);
      return;
    }
    const { streams, receiver, track, transceiver } = event;
    const [stream] = streams;
    receiver.playoutDelayHint = 0;
    if (event.track.kind === "audio") {
      track.enabled = true;
    }
    if (transceiver.mid === "vr-video") {
      if (this.vrElement) {
        Logger.log("WebRTC | onTrack: vr-video ", stream, stream.getTracks());
        this.vrElement.srcObject = new MediaStream([track]);
      }
      const videoElementTracks = [];
      stream.getTracks().forEach((t) => {
        if (t.id !== track.id) {
          videoElementTracks.push(t);
        }
      });
      Logger.log("WebRTC | onTrack: video ", videoElementTracks);
      this.videoElement.srcObject = new MediaStream(videoElementTracks);
    } else if (stream.getTracks().length > 0 && !isSafari) {
      this.videoElement.srcObject = stream;
    }
  };

  onNegotiationNeeded = (event) => {
    Logger.log("WebRTC | onNegotiationNeeded: ", event);
    setTimeout(() => {
      if (this.peerConnection && this.peerConnection.iceConnectionState !== "connected") {
        Logger.log("WebRTC | onNegotiationNeeded: timeout ", this.peerConnection.iceConnectionState);
        this.clear();
      }
    }, 6000);
  };

  onIceConnectionStateChange = (event) => {
    if (!this.peerConnection) {
      return;
    }
    Logger.log("WebRTC | onIceConnectionStateChange: ", event, this.peerConnection.iceConnectionState);
    if (this.peerConnection.iceConnectionState === "connected") {
      clearTimeout(this.disconnectControlTimeoutHandler);
    }
    if (this.peerConnection.iceConnectionState === "failed" || this.peerConnection.iceConnectionState === "closed") {
      Logger.log("WebRTC | FAILED OR CLOSED: ", event, this.peerConnection.iceConnectionState);
      this.clear();
      return;
    }
    if (this.peerConnection.iceConnectionState === "disconnected") {
      clearTimeout(this.disconnectControlTimeoutHandler);
      this.disconnectControlTimeoutHandler = setTimeout(() => {
        if (this.peerConnection && this.peerConnection.iceConnectionState !== "connected") {
          Logger.log("WebRTC | timeout controller: ", event, this.peerConnection.iceConnectionState);
          this.clear();
        }
      }, 6000);
      return;
    }
    if (this.peerConnection && this.peerConnection.iceConnectionState === "checking") {
      setTimeout(
        () => {
          if (this.peerConnection && this.peerConnection.iceConnectionState !== "connected") {
            Logger.log("WebRTC | disconnect timeout controller: ", event, this.peerConnection.iceConnectionState);
            this.clear();
          }
        },
        isFirefox ? 40000 : 4000,
      );
    }
  };

  onDataChannel = (event) => {
    Logger.log("WebRTC | onDataChannel: ", event);
    const { channel } = event;
    channel.binaryType = "arraybuffer";
    if (channel.label === "control") {
      this.dataChannel = channel;
      this.dataChannel.onmessage = this.onDataChannelMessage;
      this.dataChannel.onopen = this.onDataChannelOpen;
      this.dataChannel.onerror = this.onDataChannelError;
    }
  };

  // DATA CHANNEL FUNCTIONS

  onDataChannelMessage = (message) => {
    const e = {
      data: this.arrayBufferToString(message.data),
    };
    this.onmessage(e);
  };

  onDataChannelOpen = () => {
    Logger.log("WebRTC | onDataChannelOpen", this.onopen);
    this.readyState = WebSocket.OPEN;
    this.onopen();
  };

  onDataChannelClose = () => {
    Logger.log("WebRTC | onDataChannelClose");
    this.clear();
  };

  onDataChannelError = () => {
    Logger.log("WebRTC | onDataChannelError");
    this.clear();
  };

  onMultipleConnection = () => {
    // simulating a multiple connection msg on the main channel
    // eslint-disable-next-line quotes
    this.onmessage({ data: '{ "$type": "14" }' });
  };

  startMicrophone = (stream) => {
    const tracks = stream.getTracks();
    if (tracks.length === 0) return;

    const mic = this.peerConnection.getTransceivers().find((t) => t.mid === "mic");
    if (!mic) return;

    [this.micTrack] = tracks;
    mic.sender.replaceTrack(tracks[0]);
    mic.sender.setStreams(stream);
  };

  stopMicrophone = () => {
    if (!this.micTrack) return;

    this.micTrack.stop();
    this.micTrack = null;
  };
}
